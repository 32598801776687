var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c("b-link", { staticClass: "brand-logo mt-0 mb-2" }, [
              _c("img", {
                attrs: { src: _vm.style.loginImage, width: "150px" },
              }),
            ]),
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("Alterar senha")) + " "),
            ]),
            _c(
              "validation-observer",
              { ref: "simpleRules" },
              [
                _c(
                  "b-form",
                  {
                    staticClass: "auth-reset-password-form mt-2",
                    attrs: { method: "POST" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitForm($event)
                      },
                    },
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: _vm.$t("Senha atual"),
                          "label-for": "reset-password-old",
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: _vm.$t("Senha atual"),
                            rules: "required",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "b-input-group",
                                    {
                                      staticClass: "input-group-merge",
                                      class:
                                        errors.length > 0 ? "is-invalid" : null,
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "form-control-merge",
                                        attrs: {
                                          id: "reset-password-old",
                                          type: _vm.passwordOldFieldType,
                                          state:
                                            errors.length > 0 ? false : null,
                                          name: "reset-password-old",
                                          placeholder: "············",
                                          autocomplete: "current-password",
                                          "data-private": "",
                                        },
                                        model: {
                                          value: _vm.formData.oldPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "oldPassword",
                                              $$v
                                            )
                                          },
                                          expression: "formData.oldPassword",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              icon: _vm.passwordOldToggleIcon,
                                            },
                                            on: {
                                              click:
                                                _vm.togglePasswordOldVisibility,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: _vm.$t("Nova senha"),
                          "label-for": "reset-password-new",
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: _vm.$t("Nova senha"),
                            vid: "Password",
                            rules: "required|password",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "b-input-group",
                                    {
                                      staticClass: "input-group-merge",
                                      class:
                                        errors.length > 0 ? "is-invalid" : null,
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "form-control-merge",
                                        attrs: {
                                          id: "reset-password-new",
                                          type: _vm.password1FieldType,
                                          state:
                                            errors.length > 0 ? false : null,
                                          name: "reset-password-new",
                                          placeholder: "············",
                                          autocomplete: "new-password",
                                          "data-private": "",
                                        },
                                        model: {
                                          value: _vm.formData.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "formData.password",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              icon: _vm.password1ToggleIcon,
                                            },
                                            on: {
                                              click:
                                                _vm.togglePassword1Visibility,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-for": "reset-password-confirm",
                          label: _vm.$t("Confirmar nova senha"),
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: _vm.$t("Confirmar nova senha"),
                            rules: "required|confirmed:Password",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "b-input-group",
                                    {
                                      staticClass: "input-group-merge",
                                      class:
                                        errors.length > 0 ? "is-invalid" : null,
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "form-control-merge",
                                        attrs: {
                                          id: "reset-password-confirm",
                                          type: _vm.password2FieldType,
                                          state:
                                            errors.length > 0 ? false : null,
                                          name: "reset-password-confirm",
                                          placeholder: "············",
                                          autocomplete: "off",
                                          "data-private": "",
                                        },
                                        model: {
                                          value: _vm.formData.cPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "cPassword",
                                              $$v
                                            )
                                          },
                                          expression: "formData.cPassword",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { attrs: { "is-text": "" } },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              icon: _vm.password2ToggleIcon,
                                            },
                                            on: {
                                              click:
                                                _vm.togglePassword2Visibility,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("e-button", {
                      attrs: {
                        text: _vm.$t("Alterar senha"),
                        type: "submit",
                        block: "",
                        variant: "primary",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "text-center mt-2" },
              [
                _c(
                  "b-link",
                  { attrs: { to: { name: "auth-login" } } },
                  [
                    _c("feather-icon", { attrs: { icon: "ChevronLeftIcon" } }),
                    _vm._v(" " + _vm._s(_vm.$t("Voltar")) + " "),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }